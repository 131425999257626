import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo
      title='Everything Green| Legalised & Prescription Cannabis Website'
      description='Guides to how to obtain prescription cannabis, the country’s largest directory of clinics, legal guidelines and a lot of research – We are your one stop shop.'
    />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header='Everything Green' />
        <p>
          Everything Green was founded in 2020 with the main focus of
          simplifying the complex world of legalised and prescription cannabis
          in Australia. As well as simplifying this complex world, Everything
          Green petitions both state and federal government to help increase its
          accessibility, and houses the country’s largest database of doctors
          and clinics that provide cannabis prescriptions.{' '}
        </p>
        <p>
          It was founded by three individuals, all with a different view of
          cannabis. One of us is a habitual user of cannabis for recreational
          purposes, another a user for medicinal reasons and the third doesn’t
          use it all. The one things that binds us together is our belief in its
          ability to help with a broad spectrum of ailments including; ADHD,
          Anxiety & Depression, Epilepsy, Insomnia, Migraines, Multiple
          Sclerosis, Chronic Pain, PTSD, Cancer, Fibromyalgia, Neuropathic Pain,
          and Pre-Menstrual Syndrome.
        </p>
        <p>
          With different state laws, a separate and overarching federal law,
          navigating and understanding this changing space is difficult. Our
          mission is to provide the Australian consumer with all the facts they
          need to understand:
          <ul>
            <li>what is and isn’t legal</li>
            <li>your eligibility to obtain prescription cannabis</li>
            <li>the doctors and clinics that prescribe legal cannabis </li>
            <li>latest research, news and legislation </li>
          </ul>
        </p>

        <Divider />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
//TODO: Add internal links.
